<template>
  <div class="relative">
    <!-- Text label absolute position so buttons vertically align -->
    <p
      class="
        absolute
        inset-x-0
        text-center
        transition
        duration-300
        -top-7
        xl:text-lg xl:-top-8
      "
      :class="[isSelected ? 'font-bold' : 'font-semibold text-gray-500']"
    >
      {{ props.grade }}
    </p>
    <div
      class="
        flex
        transition
        duration-300
        border-2
        rounded-full
        cursor-pointer
        sm:hover:scale-105 sm:hover:shadow
      "
      :class="{
        'ring lg:ring-4 ring-opacity-50': isSelected,
        'bg-green-200/10 border-green-500 sm:hover:bg-green-600 sm:hover:border-green-600':
          props.colorTheme === 'green',
        'bg-lime-200/10 border-lime-500 sm:hover:bg-lime-600 sm:hover:border-lime-600':
          props.colorTheme === 'lime',
        'bg-yellow-200/10 border-yellow-500 sm:hover:bg-yellow-600 sm:hover:border-yellow-600':
          props.colorTheme === 'yellow',
        'bg-green-500 ring-green-500':
          isSelected && props.colorTheme === 'green',
        'bg-lime-500 ring-lime-500': isSelected && props.colorTheme === 'lime',
        'bg-yellow-500 ring-yellow-500':
          isSelected && props.colorTheme === 'yellow',
        'w-7.5 h-7.5 md:w-8 md:h-8 xl:w-9 xl:h-9': props.size === 7.5,
        'w-7.75 h-7.75 md:w-8.5 md:h-8.5 xl:w-9.5 xl:h-9.5':
          props.size === 7.75,
        'w-8 h-8 md:w-9 md:h-9 xl:w-10 xl:h-10': props.size === 8,
        'w-8.25 h-8.25 md:w-9.25 md:h-9.25 xl:w-10.25 xl:h-10.25':
          props.size === 8.25,
        'w-8.5 h-8.5 md:w-9.5 md:h-9.5 xl:w-10.5 xl:h-10.5': props.size === 8.5,
        'w-8.75 h-8.75 md:w-9.75 md:h-9.75 xl:w-10.75 xl:h-10.75':
          props.size === 8.75,
        'w-9 h-9 md:w-10 md:h-10 xl:w-11 xl:h-11': props.size === 9,
      }"
      @click="updateScore({ subject: props.subject, score: props.score })"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="block w-6 h-6 mx-auto my-auto transition duration-300"
        :class="[isSelected ? 'opacity-100' : 'opacity-0']"
        :fill="
          {
            green: '#D1FAE5',
            lime: '#ECFCCB',
            yellow: '#FFF9C3',
          }[props.colorTheme]
        "
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <polyline
          points="216 72.005 104 184 48 128.005"
          fill="none"
          :stroke="
            {
              green: '#D1FAE5',
              lime: '#ECFCCB',
              yellow: '#FFF9C3',
            }[props.colorTheme]
          "
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></polyline>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const props = defineProps({
  subject: {
    type: String,
    required: true,
  },
  score: {
    type: Number,
    required: true,
  },
  grade: {
    type: String,
    required: true,
  },
  size: {
    type: Number,
    required: true,
  },
  colorTheme: {
    type: String,
    required: true,
  },
});

const store = useStore();
const scores = computed(() => store.state.scores);
const isSelected = computed(() => scores.value[props.subject] === props.score);

const updateScore = (subScore) => store.dispatch("updateScore", subScore);
</script>
