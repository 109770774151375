<template>
  <div class="px-3.5 py-3 2xl:py-3.5 rounded-xl bg-blue-gray-100 shadow">
    <p class="mb-8 text-lg font-semibold">
      {{ props.desc }}
      <span class="text-sm text-blue-gray-600"
        >({{ score }}<span class="text-xs font-bold">RP</span>)</span
      >
    </p>
    <div
      class="
        grid
        items-center
        content-center
        justify-between
        grid-cols-7
        lg:mx-6
        justify-items-center
      "
    >
      <app-radios-button
        v-for="(score, index) in props.scoreList"
        :size="[9, 8.75, 8.5, 8.25, 8, 7.75, 7.5][index]"
        :color-theme="
          {
            10: 'green',
            8.75: 'green',
            7.5: 'lime',
            6.25: 'lime',
            5: 'lime',
            2.5: 'yellow',
            0: 'yellow',
          }[score]
        "
        :score="getLevel(props.subject) * score"
        :grade="getH1Grade(score)"
        :subject="props.subject"
        :key="props.subject + score"
      />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import AppRadiosButton from "@/components/AppRadiosButton.vue";

const props = defineProps({
  subject: {
    type: String,
    required: true,
  },
  scoreList: {
    type: Array,
    required: true,
  },
  desc: {
    type: String,
    required: true,
  },
});

const store = useStore();
const scores = computed(() => store.state.scores);
const score = computed(() => scores.value[props.subject]);

const getH1Grade = function (score) {
  return { 10: "A", 8.75: "B", 7.5: "C", 6.25: "D", 5: "E", 2.5: "S", 0: "U" }[
    score
  ];
};

const getLevel = function (subject) {
  return subject.slice(-2) === "H2" ? 2 : 1;
};
</script>
