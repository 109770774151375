<template>
  <section class="container px-3 pt-16 pb-16 mx-auto space-y-7 sm:px-0">
    <!-- Sources Section -->
    <div class="leading-7">
      <h2 class="mb-4 text-2xl font-bold text-blue-gray-800">Sources</h2>

      <div
        :class="[
          props.page === 'courses'
            ? 'grid md:grid-cols-2 lg:grid-cols-3 gap-8'
            : '',
        ]"
      >
        <!-- IGP Sources -->
        <div v-if="props.page !== 'prospects'">
          <h3 class="mb-6 font-semibold leading-5">
            Indicative Grade Profile (IGP) for Academic Year /2022
          </h3>
          <ul class="list-disc list-inside space-y-7">
            <li>
              <a
                href="https://www.nus.edu.sg/oam/undergraduate-programmes/indicative-grade-profile-(igp)"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >NUS IGP</a
              >
            </li>
            <li>
              <a
                href="https://www.ntu.edu.sg/admissions/undergraduate/indicative-grade-profile"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >NTU IGP</a
              >
            </li>
            <li>
              <a
                href="https://admissions.smu.edu.sg/admissions-requirements/indicative-grade-profile"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >SMU IGP</a
              >
            </li>
          </ul>
        </div>

        <!-- GES Sources -->
        <div
          v-if="props.page !== 'calculator'"
          class="md:row-span-2 lg:row-auto"
        >
          <h3 class="mb-6 font-semibold leading-5">
            Graduate Employment Survey (GES)
          </h3>
          <ul class="list-disc list-inside space-y-7">
            <li>
              <a
                href="https://www.moe.gov.sg/-/media/files/post-secondary/ges-2022/web-publication-nus-ges-2022.ashx"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >NUS GES 2022</a
              >
            </li>
            <li>
              <a
                href="https://www.moe.gov.sg/-/media/files/post-secondary/ges-2022/web-publication-ntu-ges-2022.ashx"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >NTU GES 2022</a
              >
            </li>
            <li>
              <a
                href="https://www.moe.gov.sg/-/media/files/post-secondary/ges-2022/web-publication-smu-ges-2022.ashx"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >SMU GES 2022</a
              >
            </li>
            <li>
              <a
                href="https://www.moe.gov.sg/-/media/files/post-secondary/ges-2022/web-publication-sutd-ges-2022.ashx"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >SUTD GES 2022</a
              >
            </li>
            <li>
              <a
                href="https://www.moe.gov.sg/-/media/files/post-secondary/ges-2022/web-publication-sit-ges-2022.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >SIT GES 2022</a
              >
            </li>
            <li>
              <a
                href="https://www.moe.gov.sg/-/media/files/post-secondary/ges-2022/web-publication-suss-ges-2022.ashx"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >SUSS GES 2022</a
              >
            </li>
            <li>
              <a
                href="https://data.gov.sg/dataset/graduate-employment-survey-ntu-nus-sit-smu-suss-sutd"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >Data.gov.sg GES</a
              >
            </li>
          </ul>
        </div>

        <!-- Student Statistics Sources -->
        <div v-if="props.page === 'courses'">
          <h3 class="mb-6 font-semibold leading-5">Student Statistics</h3>
          <ul class="list-disc list-inside space-y-7">
            <li>
              <a
                href="https://www.nus.edu.sg/registrar/docs/info/student-statistics/enrolment-statistics/undergraduate-studies/ug-enrol-20232024.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >NUS Student Statistics</a
              >
            </li>
            <li>
              <a
                href="https://www.ntu.edu.sg/docs/default-source/office-of-data-and-analytics/ay2023-undergraduate-student-enrolment.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >NTU Student Statistics</a
              >
            </li>
            <li>
              <a
                href="https://www.smu.edu.sg/sites/default/files/smu/statistical_highlights_20220912.pdf"
                target="_blank"
                rel="noopener noreferrer"
                class="text-lg font-medium underline sm:hover:text-sky-700"
                >SMU Student Statistics</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Notes Section -->
    <div class="text-sm tracking-tight text-justify">
      <h2
        class="
          mb-4
          text-2xl
          font-bold
          tracking-normal
          text-left text-blue-gray-800
        "
      >
        Notes
      </h2>

      <!-- IGP Notes -->
      <template v-if="props.page === 'calculator' || props.page === 'courses'">
        <p class="mb-3">
          Grade Profile rank points refer to the grades scored by
          Singapore-Cambridge A level applicants in their three H2 and one H1
          content subjects while GPA refers to the Grade Point Average scored by
          Singapore Polytechnic students.
        </p>

        <!-- RP Score and Eligibility Notes -->
        <template v-if="props.page === 'calculator'">
          <p class="mb-3">
            The scores for each course shown represent the grade profiles from
            the 10th percentile of applicants admitted into a particular course,
            including those from aptitude-based admissions.
          </p>

          <p class="mb-3">
            Eligibility is based on whether your score is higher than the grade
            profile for a particular course.
          </p>

          <p class="mb-3">
            <a
              href="https://www.nus.edu.sg/oam/undergraduate-programmes/1st-Choice"
              class="sm:hover:underline text-sky-800"
              target="_blank"
              rel="noopener noreferrer"
              >NUS' First Point Bonus Points Scheme</a
            >
            may allow an additional 2.5 bonus points to be added to the
            admission score for first choice non-interview single degree courses
            in NUS. The website does not factor this in for course eligibility.
          </p>

          <p class="mb-3">
            Only courses with the necessary Indicative Grade Profile (IGP) data
            available are included in the website. The estimated eligible number
            of courses will be limited to some courses from NUS, NTU and SMU.
            Courses with insufficient IGP data (courses from SUTD, SUSS and SIT)
            are not considered in the number of eligible courses.
          </p>

          <p class="mb-3">
            Both General Paper (GP) and Project Work (GP) are assumed to be
            graded C, although this may inaccurate in more competitive courses
            or courses where the a grade higher than C for GP is required.
          </p>
        </template>

        <!-- General IGP notes -->
        <p class="mb-3">
          Courses may have additional requirements, such as having minimum
          grades in specific subjects. You may find these details in each
          university's respective admission websites.
        </p>

        <p class="mb-3">
          Having the same grades or score from the AY2023/2024 IGP does not
          guarantee a successful admission to that course.
        </p>

        <p class="mb-3">
          Certain courses may have additional requirements, like selection tests
          and interviews (e.g. Law, Medicine).
        </p>
      </template>

      <!-- GES Notes -->
      <template v-if="props.page === 'prospects' || props.page === 'courses'">
        <p class="mb-3">
          Median income above refers to the median gross monthly salary based on
          the respective year's Graduate Employment Survey (GES) results. This
          only includes full-time permanently employed graduates.
        </p>

        <p class="mb-3">
          Employment rates above refer to the percentage of graduates in
          full-time permanent employment with respect to the number graduates in
          the labour force. Graduates in the labour force refers to those who
          are working, or not working but actively seeking and available for
          work approximately half a year after completing their final
          examinations. Full-time permanent employment refers to employment of
          minimally 35 hours per week. This is inclusive of those on contracts
          of at least a year.
        </p>

        <!-- Top GES Results Notes -->
        <template v-if="props.page !== 'courses'">
          <p class="mb-3">
            The GES Notes above excludes results where only graduates with
            Honours or Cum Laude were included.
          </p>
        </template>
      </template>

      <p class="mt-4 mb-1 font-bold">
        <router-link
          class="underline transition duration-200 sm:hover:text-sky-800"
          :to="{ name: 'terms' }"
          >Terms and Conditions</router-link
        >
        - Section 2
      </p>

      <blockquote>
        <p class="mb-3">
          We are not responsible if information made available on this site is
          not accurate, complete or current. The material on this site is
          provided for general information only and should not be relied upon or
          used as the sole basis for making decisions without consulting
          primary, more accurate, more complete or more timely sources of
          information. Any reliance on the material on this site is at your own
          risk.
        </p>

        <p class="mb-3">
          This site may contain certain historical information. Historical
          information, necessarily, is not current and is provided for your
          reference only. We reserve the right to modify the contents of this
          site at any time, but we have no obligation to update any information
          on our site. You agree that it is your responsibility to monitor
          changes to our site.
        </p>
      </blockquote>
    </div>
  </section>
</template>

<script setup>
const props = defineProps({
  page: {
    type: String,
    required: true,
    validator(value) {
      // The value must match one of these pages
      return ["calculator", "prospects", "courses"].includes(value);
    },
  },
});
</script>
