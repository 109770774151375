<template>
  <main>
    <!-- Courses Header -->
    <the-heading background-image="study">
      <template #header>
        <p
          class="
            mb-2
            text-sm
            tracking-wider
            md:text-base
            xl:text-lg
            text-blue-gray-50
          "
        >
          SGUniStats | Calculator
        </p>
        <h1
          class="
            text-2xl
            font-semibold
            leading-7
            md:text-3xl
            xl:text-4xl
            text-blue-gray-50
          "
        >
          Assess your chances of a successful&nbsp;application
        </h1>
      </template>
    </the-heading>

    <!-- A level Results Calculator -->
    <section class="container px-3 pt-4 pb-16 mx-auto sm:px-0 sm:pt-8">
      <div class="flex items-center gap-2 mb-2 text-blue-gray-800">
        <h2 class="text-2xl font-bold">Rank Point Calculator</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-7 h-7"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <rect
            x="32"
            y="48"
            width="192"
            height="160"
            stroke-width="24"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            fill="none"
            rx="8"
            transform="translate(256) rotate(90)"
          ></rect>
          <line
            x1="92"
            y1="76"
            x2="164"
            y2="76"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <circle cx="104" cy="128" r="16"></circle>
          <circle cx="152" cy="128" r="16"></circle>
          <circle cx="104" cy="176" r="16"></circle>
          <circle cx="152" cy="176" r="16"></circle>
        </svg>
      </div>
      <h3 class="mb-6 font-medium leading-5">
        Calculate your University Admission Score (UAS) /
        Rank&nbsp;Points&nbsp;(RP). Updated for 2024!
      </h3>
      <div class="mb-8 space-y-6">
        <app-radios
          v-for="subject in subjects"
          :subject="subject"
          :score-list="scoreList"
          :desc="subjectDesc[subject]"
          :key="subject"
        />
      </div>
      <h3 class="pt-2 mb-2 text-xl font-bold text-blue-gray-800">
        A level Results
      </h3>
      <p class="text-lg">
        Your Score:
        <span class="font-semibold">
          {{ +parseFloat(rankPointsOutput).toFixed(3) }}</span
        ><span class="text-sm font-bold">RP</span>
      </p>
      <p class="mb-8 text-lg">
        Estimated Eligible Courses:
        <span class="font-semibold">
          {{ +parseFloat(eligibleCoursesCountOutput).toFixed(0) }}</span
        >
      </p>
      <app-table />
    </section>

    <app-hr />

    <section class="container px-3 pt-16 pb-16 mx-auto sm:px-0">
      <h2 class="mb-1 text-2xl font-bold text-blue-gray-800">
        Course Eligibility Estimate
      </h2>
      <p class="mb-6 font-medium leading-5">
        Find out which courses are likely to accept your application based on
        the IGP. Updated for AY2023/2024. Note that courses with no IGP are not
        listed.
      </p>

      <div class="flex flex-col lg:flex-row gap-4 gap-5 mb-8 md:flex">
        <app-toggle
          v-model:toggleState="filterDefault"
          toggleLabel="Filter"
          defaultValue="Likely Eligible"
          otherValue="All Courses"
        />
        <app-toggle
          v-model:toggleState="sortDefault"
          toggleLabel="Sort"
          defaultValue="By Rank Points"
          otherValue="Alphabetical"
        />
      </div>

      <p class="mb-2 text-sm leading-5 text-blue-gray-600 sm:text-base">
        The lists below automatically update with your score. Click on any of
        the courses to learn more.
      </p>

      <app-accordion
        v-for="(university, index) in universitiesWithIGP"
        :university="university"
        :uni-courses="filterUniCop(sortedCourses[university])"
        :filter-default="filterDefault"
        :sort-default="sortDefault"
        :color-theme="accordionColors[index]"
        :key="university"
      />
    </section>

    <app-hr />

    <app-list-cop v-once />

    <app-hr />

    <app-cards :hiddenCards="['calculator']" desc="More Tools" />

    <app-hr />

    <app-notes page="calculator" />

    <transition name="slide-y">
      <app-calculator-results v-show="showResults" />
    </transition>
  </main>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

import TheHeading from "@/components/TheHeading.vue";
import AppRadios from "@/components/AppRadios.vue";
import AppTable from "@/components/AppTable.vue";
import AppCalculatorResults from "@/components/AppCalculatorResults.vue";
import AppToggle from "@/components/AppToggle.vue";
import AppAccordion from "@/components/AppAccordion.vue";
import AppListCop from "@/components/AppListCop.vue";
import AppNotes from "@/components/AppNotes.vue";
import AppCards from "@/components/AppCards.vue";
import AppHr from "@/components/AppHr.vue";

import { useTransition, TransitionPresets } from "@vueuse/core";

import courses from "@/data/courses.json";
import sortedCourses from "@/data/sortedCourses.json";

const subjects = [
  "subFirstH2",
  "subSecondH2",
  "subThirdH2",
  "subH1",
  "subGP",
  "subPW",
  "subMTL",
];
const subjectDesc = {
  subFirstH2: "1st H2",
  subSecondH2: "2nd H2",
  subThirdH2: "3rd H2",
  subH1: "H1 / 4th H2",
  subGP: "GP",
  subPW: "PW",
  subMTL: "MTL",
};
const scoreList = [10, 8.75, 7.5, 6.25, 5, 2.5, 0];
const accordionColors = ["sky", "blue", "indigo"];
const universitiesWithIGP = [
  "National University of Singapore",
  "Nanyang Technological University",
  "Singapore Management University",
];

const filterDefault = ref(true);
const sortDefault = ref(true);

const store = useStore();

const rankPoints = computed(() => store.getters.rankPoints);
const rankPointsOutput = useTransition(rankPoints, {
  duration: 300,
  transition: TransitionPresets.linear,
});

const eligibleCourses = computed(() => store.getters.eligibleCourses);
const eligibleCoursesCount = computed(() => eligibleCourses.value.length);
const eligibleCoursesCountOutput = useTransition(eligibleCoursesCount, {
  duration: 300,
  transition: TransitionPresets.linear,
});

const filterUniCop = (uniCourses) => {
  return (
    Object.entries(uniCourses)
      // eslint-disable-next-line no-unused-vars
      .map(([_, facultyCourses]) => {
        // Remove all courses with no COP
        return [_, facultyCourses.filter((id) => courses[id].cop)];
      })
      // eslint-disable-next-line no-unused-vars
      .filter(([_, facultyCourses]) => {
        // Remove all faculties with no courses with COP
        return facultyCourses.length;
      })
  );
};

const showResults = ref(false);
// onMounted(() => setTimeout(() => (showResults.value = true), 500));
onMounted(() => (showResults.value = true));
</script>

<style scoped>
.slide-y-enter-active,
.slide-y-leave-active {
  transition: all 0.5s ease-out;
}
.slide-y-enter-active {
  transition-delay: 0.2s;
}
.slide-y-enter-from,
.slide-y-leave-to {
  opacity: 0;
  transform: translateY(200px);
}
</style>
