<template>
  <h2 class="mb-4 text-lg font-bold leading-6 sm:text-lg text-blue-gray-800">
    How your A level rank points are&nbsp;calculated
  </h2>

  <table
    class="
      w-3/5
      text-lg text-center
      border
      rounded-lg
      table-fixed
      sm:w-1/2
      border-sky-600
    "
  >
    <thead class="bg-sky-200 text-blue-gray-800">
      <tr>
        <th class="w-1/3 border border-sky-600">Grade</th>
        <th class="w-1/3 border border-sky-600">H1</th>
        <th class="w-1/3 border border-sky-600">H2</th>
      </tr>
    </thead>
    <tbody class="bg-sky-50">
      <tr class="odd:bg-sky-100">
        <td class="font-semibold border border-sky-600">A</td>
        <td class="border border-sky-600">10</td>
        <td class="border border-sky-600">20</td>
      </tr>
      <tr class="odd:bg-sky-100">
        <td class="font-semibold border border-sky-600">B</td>
        <td class="border border-sky-600">8.75</td>
        <td class="border border-sky-600">17.5</td>
      </tr>
      <tr class="odd:bg-sky-100">
        <td class="font-semibold border border-sky-600">C</td>
        <td class="border border-sky-600">7.5</td>
        <td class="border border-sky-600">15</td>
      </tr>
      <tr class="odd:bg-sky-100">
        <td class="font-semibold border border-sky-600">D</td>
        <td class="border border-sky-600">6.25</td>
        <td class="border border-sky-600">12.5</td>
      </tr>
      <tr class="odd:bg-sky-100">
        <td class="font-semibold border border-sky-600">E</td>
        <td class="border border-sky-600">5</td>
        <td class="border border-sky-600">10</td>
      </tr>
      <tr class="odd:bg-sky-100">
        <td class="font-semibold border border-sky-600">S</td>
        <td class="border border-sky-600">2.5</td>
        <td class="border border-sky-600">5</td>
      </tr>
      <tr class="odd:bg-sky-100">
        <td class="font-semibold border border-sky-600">U</td>
        <td class="border border-sky-600">0</td>
        <td class="border border-sky-600">0</td>
      </tr>
    </tbody>
  </table>
</template>
