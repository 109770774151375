<template>
  <div
    class="
      fixed
      z-50
      transition-all
      duration-300
      rounded-lg
      shadow-lg
      bottom-3
      right-3
      bg-amber-50
    "
    :class="[!openResults ? 'w-28' : !scores.subMTL ? 'w-36' : 'w-41']"
  >
    <div
      class="
        flex
        items-center
        justify-between
        px-2
        py-1
        transition-all
        duration-300
        rounded-t-lg
        cursor-pointer
        bg-amber-550
        text-amber-50
        gap-x-2
        sm:hover:bg-amber-650
      "
      :class="{ 'rounded-b-lg': !openResults }"
      @click="openResults = !openResults"
    >
      <p class="font-semibold">Results</p>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-4 h-4 transition duration-300"
        :class="{ 'rotate-90': !openResults }"
        fill="#fffbeb"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <polyline
          points="96 48 176 128 96 208"
          fill="none"
          stroke="#fffbeb"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></polyline>
      </svg>
    </div>
    <!-- Content subjects -->
    <div
      class="
        px-2
        overflow-hidden
        text-xs
        font-medium
        transition-all
        duration-300
        rounded-b-lg
        max-h-0
      "
      :class="{ 'max-h-96 py-1': openResults }"
    >
      <!-- Higher scoring results -->
      <p class="whitespace-nowrap">
        <span class="text-lg font-semibold">{{
          +parseFloat(rankPointsOutput).toFixed(3)
        }}</span
        ><span class="font-bold">RP&nbsp;</span>
        <span
          v-show="scores.subMTL"
          :class="{ underline: rankPoints !== lowerPoints }"
          >{{ usesMTL ? "with MTL" : "without MTL" }}</span
        >
      </p>
      <!-- Lower scoring results -->
      <p v-show="scores.subMTL" class="mb-1 whitespace-nowrap">
        <span class="text-lg font-semibold">{{
          +parseFloat(lowerPointsOutput).toFixed(3)
        }}</span
        ><span class="font-bold">RP&nbsp;</span>
        <span v-show="scores.subMTL">{{
          !usesMTL ? "with MTL" : "without MTL"
        }}</span>
      </p>
      <!-- Content subjects grades -->
      <p v-show="rankPoints">
        <span class="mb-1 text-sm font-semibold">{{ contentGrades }}</span>
        grades
      </p>
      <!-- Number of eligible Courses -->
      <p v-show="rankPoints" class="overflow-clip whitespace-nowrap">
        <span class="text-sm font-semibold"
          >{{ +parseFloat(eligibleCoursesCountOutput).toFixed(0) }}~
        </span>
        <span class="text-xs"> eligible courses</span>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";

import { useTransition, TransitionPresets } from "@vueuse/core";

const openResults = ref(true);

const store = useStore();
const scores = computed(() => store.state.scores);

const usesMTL = computed(() => store.getters.usesMTL);

const eligibleCourses = computed(() => store.getters.eligibleCourses);
const eligibleCoursesCount = computed(() => eligibleCourses.value.length);
const eligibleCoursesCountOutput = useTransition(eligibleCoursesCount, {
  duration: 300,
  transition: TransitionPresets.linear,
});

const rankPoints = computed(() => store.getters.rankPoints);
const rankPointsOutput = useTransition(rankPoints, {
  duration: 300,
  transition: TransitionPresets.linear,
});

const lowerPoints = computed(() =>
  usesMTL.value
    ? rankPoints.value * (10 / 9) - scores.value.subMTL
    : (rankPoints.value + scores.value.subMTL) * 0.9
);
const lowerPointsOutput = useTransition(lowerPoints, {
  duration: 300,
  transition: TransitionPresets.linear,
});

const getH1Grade = function (score) {
  return { 10: "A", 8.75: "B", 7.5: "C", 6.25: "D", 5: "E", 2.5: "S", 0: "U" }[
    score
  ];
};

const contentGrades = computed(
  () =>
    `${[
      scores.value.subFirstH2,
      scores.value.subSecondH2,
      scores.value.subThirdH2,
    ]
      .map((score) => getH1Grade(score / 2))
      .sort()
      .join("")}/${getH1Grade(scores.value.subH1)}`
);
</script>
